const GDPR_ENABLED = "_GDPR_ENABLED",
      COOKIEBAR_HTML = `<div class="cookiebar">
    <div>
      <h2>This website uses cookies</h2>
      <p>We use cookies to be able to provide social media features, analyse our traffic and behaviour of the visitors on our website and for marketing purposes.
        Sometimes we share this anonymised information to 3rd party partner companies.
        <a href="https://www.eucookie.eu/en/cookieinfo/">More information</a></p>
      <a id="acceptCookies" class="btn">I accept Cookies</a>
    </div>
  </div>`;

export default function cookiebar() {
  if ( localStorage.getItem( GDPR_ENABLED ) ) {
    return;
  }
  document.body.insertAdjacentHTML( "beforeend", COOKIEBAR_HTML );
  const cookiebarEl = document.querySelector( ".cookiebar" ),
        acceptBtn = cookiebarEl.querySelector( "#acceptCookies" );

  acceptBtn.addEventListener( "click", ( e ) => {
    e.preventDefault();
    localStorage.setItem( GDPR_ENABLED, "true" );
    document.body.removeChild( cookiebarEl );
  }, false );
}