import "lazysizes/plugins/rias/ls.rias.js";
// import "lazysizes/plugins/blur-up/ls.blur-up.js";

import "lazysizes";
import mediumZoom from "medium-zoom";
import cookiebar from "./components/cookiebar";

cookiebar();

document.addEventListener( "lazybeforeunveil", () => {
  mediumZoom( "[data-zoom-src]" );
});

